
/* Table column header */
.iybuay{
    display: flex!important;
    width: 100%!important;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 600!important;
    font-size: 16px!important;
    line-height: 26px!important;
    /* font-family: Gilroy; */
    letter-spacing: "0px"!important;
    color:#4C4C4C !important;
    opacity: 1!important;
}
a {
    color: #4C4C4CCC !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.css-11qu8st-MuiPaper-root-MuiCard-root {
    height: 100%;
}




.react-tel-input .form-control {
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 300px;
    outline: none;
    padding: 18px 16px 17px 60px;
    transition: box-shadow ease .25s,border-color ease .25s;
    color: #495057;
}
