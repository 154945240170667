.flatpickr-month,
.flatpickr-monthDropdown-months,
.flatpickr-months,
.flatpickr-monthDropdown-month,
.startRange,
.endRange,
.flatpickr-calender
{
  /* background: #42427C !important; */
  background: #002c6b !important;
  color: white;
}
.flatpickr-months .flatpickr-month {
  padding-bottom: 5px;
}
.flatpickr-weekdays,.flatpickr-weekdaycontainer {
  background: white 
}
.flatpickr-day {
  border: none !important;
}

/* .flatpickr-weekday{
  background: #42427C !important;
  color:#FFFFFF !important;
} */

.path{
  background: #FFFFFF !important
}

/* svg {
  color:#42427C 
} */
.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  color : white;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  color: white;
}
.flatpickr-current-month input.cur-year {
  color : white;
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  fill: white;
}
/* .numInputWrapper span.arrowUp:hover {
  fill: white;
}
.numInputWrapper span.arrowDown:hover {
  fill: white;
} */
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgb(255 255 255 / 90%);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgb(255 255 255 / 90%);
}