.react-datepicker__week .react-datepicker__week--selected{
  background-color: #022C6B !important;
}

.react-datepicker__year-text--selected{
  background-color: #022C6B !important;
}

.react-datepicker__month-text--selected{
  background-color: #022C6B !important;
  color: #FFFF !important;
}

 .react-datepicker__day--selected{
  background-color: #022C6B !important;
}

.react-datepicker__week-number--selected{
  background-color: #022C6B !important;
}

.react-datepicker__year-text--keyboard-selected{
  background-color: #FFF !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #FFF !important;
}

.react-datepicker__week-number--keyboard-selected{
  background-color: #FFF !important;
}